import React from 'react';
import {Col, Alert} from 'reactstrap';

const VotePlaceholder = ({text}) => {

    return (
      <Col>
          <Alert color="primary" className="text-center d-flex align-items-center justify-content-center" style={{height: "100px"}}>
              {text}
          </Alert>
      </Col>
    );
}

export default VotePlaceholder;
