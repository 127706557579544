// Clean up pathname value in location props
// to only include alphanumerical characters
export const cleanLocationProp = (pathname) => {
    let re = /[^a-z0-9+]+/gi;
    if (/^\/$/ig.test(pathname)) { return "home" }
    return pathname.replace(re, '');
};

// Utility function for updating state objects
export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

// Create HTML markup i.e. from props
export const createMarkup = (HTMLString) => {
    return {__html: addExternalLinkAttributes(HTMLString)};
};

// Add `rel` attributes to external links in given string
export const addExternalLinkAttributes = (HTMLString) => {
    const re = /(href=.?)(https?)([^>]+)/gi;
    return HTMLString.replace(re, 'rel="external noopener" $&');
};

// Check if link is external link via regex
export const isExternalLink = (linkTarget) => {
    return /https?:\/\//.test(linkTarget);
};

// @Todo: Integrate error messages in utility
// Utility function for checking form field validity
export const checkValidity = (value, rules) => {
    if (rules.required && rules.required.value) {
        // Check if input field has non-whitespace value
        if (typeof value === 'boolean' && !value) {
            return {
                isValid: false,
                errorMessage: rules.required.errorMessage
            }
        } else {
            if (!value || (typeof value === 'string' && value.trim() === '')) {
                return {
                    isValid: false,
                    errorMessage: rules.required.errorMessage
                };
            }
        }
    }

    if (rules.maxLength) {
        if (value && value.length > rules.maxLength.value) {
            return {
                isValid: false,
                errorMessage: rules.maxLength.errorMessage
            };
        }
    }

    if (rules.minLength) {
        if (!value || value.length < rules.minLength.value) {
            return {
                isValid: false,
                errorMessage: rules.minLength.errorMessage
            };
        }
    }

    if (rules.isEmail && rules.isEmail.value) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        if (value && !pattern.test(value)) {
            return {
                isValid: false,
                errorMessage: rules.isEmail.errorMessage
            };
        }
    }

    if (rules.isNumeric && rules.isNumeric.value) {
        const pattern = /^\d+$/;
        if (value && !pattern.test(value))
            return {
                isValid: false,
                errorMessage: rules.isNumeric.errorMessage
            };
    }
    if (rules.isSMSCode && rules.isSMSCode.value){
        const pattern = /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZ]+$/i;
        if (value && !pattern.test(value))
            return {
                isValid: false,
                errorMessage: rules.isSMSCode.errorMessage
            };
    }

    if (rules.isInstagram && rules.isInstagram.value) {
        const usernamePattern = /^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/;
        const profileLinkPattern = /^(http(s)?:\/\/)?(www\.)?(instagram\.com|instagr\.am)\/[\w](?!.*?\.{2})[\w.]{1,28}[\w]\/?$/; // Regular expression for validating Instagram profile link
        if (value && !(usernamePattern.test(value) || profileLinkPattern.test(value)))
                return {
                isValid: false,
                errorMessage: rules.isInstagram.errorMessage
            };
    }

    if (rules.isFacebook && rules.isFacebook.value) {
        const usernamePattern = /^[\w](?!.*?\.{2})[\w.]{1,50}[\w]$/; // Regular expression for validating Facebook profile name
        const profileLinkPattern = /^(?:https?:\/\/)?(?:www\.)?(facebook\.com)\/(?!.*\.(?:com|net))[\w.]{3,50}$/;

        if (value && !(usernamePattern.test(value) || profileLinkPattern.test(value)))
            return {
                isValid: false,
                errorMessage: rules.isFacebook.errorMessage
            };
    }

    if (rules.isYouTube && rules.isYouTube.value) {
        const channelLinkPattern = /^(https?:\/\/)?(www\.)?(youtube\.com)\/([a-zA-Z0-9ÄÖÜäöüß\-_@]{1,100})$/; // Regular expression for validating YouTube channel name
        const channelNamePattern = /^[a-zA-Z0-9ÄÖÜäöüß\-_@]{1,100}$/; // Regular expression for validating Facebook profile name

        if (value && !(channelLinkPattern.test(value) || channelNamePattern.test(value)))
            return {
                isValid: false,
                errorMessage: rules.isYouTube.errorMessage
            };
    }

    return {
        isValid: true,
    };
};

/**
 * @Todo construct utility functions for:
 * maxLength (see above)
 * minLength (see above)
 * allowedCharacters (regex?)
 * providerPrefixCheck (function)
 */

export const isValidPrefix = (phoneNumber) => {
    const numberPrefixes =  [
        '0150',
        '0151',
        '0152',
        '01525',
        '0155',
        '0157',
        '0159',
        '0160',
        '0162',
        '0163',
        '0170',
        '0171',
        '0172',
        '0173',
        '0174',
        '0175',
        '0176',
        '0177',
        '0178',
        '0179'
    ];

    for (let i = 0; i <= numberPrefixes.length; i++) {
        console.log(numberPrefixes[i]);
        if (phoneNumber.startsWith(numberPrefixes[i])){
            return true;
        }
    }
    return false;
}

export const sendVirtualPageView = (pageTitle) => {
    /* eslint-disable */
    if(typeof(_etracker) === "object") {
        et_eC_Wrapper({
            et_et: process.env.REACT_APP_ETRACKER_ID,
            et_pagename: `${process.env.REACT_APP_PROJECT_TITLE}: ${pageTitle} [VPV]`
        });
    }
    /* eslint-disable */
}
