import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {Modal} from 'reactstrap';
import ConsentManager from '../../../ConsentManager/ConsentManager';
import Cookies from 'js-cookie';
import {personalization, statisticTracking} from "../../../../shared/trackingScripts";

class ConsentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: true,
            statisticScript: '',
            personalizationScript: ''
        };

        this.toggle = this.toggle.bind(this);
        this.consentCallback = this.consentCallback.bind(this);
        this.statisticHandler = this.statisticHandler.bind(this);
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    };

    consentCallback = (consentState) => {
        // check if user clicked the accept all button
        if (Object.values(consentState).every(Boolean)) {
            // turn everything on:
            this.statisticHandler();
            this.personalizationHandler();
        }
        // user has clicked the selection button, therefore check the state of selection:
        else {
            if (consentState.statisticCheck) {
                if (consentState.personalizationCheck) {
                    this.statisticHandler();
                    this.personalizationHandler();
                } else if (consentState.performanceCheck) {
                    this.statisticHandler();
                    // this.performanceHandler();
                } else {
                    this.statisticHandler();
                }
            } else if (consentState.personalizationCheck) {
                Cookies.set('bigfoot_statistic-consent', false, {expires: 180})
                if (consentState.performanceCheck) {
                    this.personalizationHandler();
                    // this.performanceHandler();
                } else {
                    this.personalizationHandler();
                }
            } else {
                Cookies.set('bigfoot_statistic-consent', false, {expires: 180})
                Cookies.set('bigfoot_personalization-consent', false, {expires: 180})
                // only performance is selected, the other options are already checked.
                // this.performanceHandler();
            }
        }
        this.toggle();
    };

    statisticHandler = () => {
        let statisticScript = statisticTracking();

        this.setState({statisticScript: statisticScript}, () => {
            Cookies.set('bigfoot_statistic-consent', true, {expires: 180})
        });
    };

    personalizationHandler = () => {
        let personalizationScript = personalization();

        this.setState({personalizationScript: personalizationScript}, () => {
            Cookies.set('bigfoot_personalization-consent', true, {expires: 180})
        });
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>{this.state.statisticScript}</Helmet>
                <Modal size="lg" scrollable backdrop={'static'} keyboard={false} isOpen={this.state.modal} toggle={this.toggle}>
                    <ConsentManager consentCallback={this.consentCallback} pages={this.props.pages}/>
                </Modal>
            </React.Fragment>
        )
    }
}

export default ConsentModal;


