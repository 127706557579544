import React from "react";
import "./CarouselAssets.module.scss";
import icon from "../../../../assets/images/sms-feature-ablaufgrafik-mobil-step1-rgb.svg";
import {ReactSVG} from "react-svg";

/**
 * #96e3fa lightest
 * #5fcced lighter
 * #00a2d4 base
 * #009dd4 base
 */

function Icon({afterInjection}) {
  return (
      afterInjection ? <ReactSVG src={icon} afterInjection={afterInjection}/> : <ReactSVG src={icon}/>
  );
}

export default Icon;
