import React from 'react';
import { Row, Col } from 'reactstrap';
import ContentLoader from "react-content-loader"

// Todo: Match visual placeholder style with final project list item style
const projectListItemPlaceholder = props => {
    let windowSize = window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    let mobileView = windowSize < 575;

    if(mobileView){
        return(
            <Row>
                <Col>
                    <ContentLoader
                        height={120}
                        width={100}
                        speed={2}
                        primarycolor="#f3f3f3"
                        secondarycolor="#ecebeb"
                        style={{ width: '100%' }}
                        {...props}
                    >
                        <rect x="1%" y="1%" rx="0" ry="0" height="98%" style={{ width: '24%' }}/>
                        <rect x="26%" y="1%" rx="0" ry="0" height="98%" style={{ width: '73%' }}/>

                    </ContentLoader>
                </Col>
            </Row>
        )
    }
    else {
        return(
            <Row>
                <Col>
                    <ContentLoader
                        height={70}
                        width={100}
                        speed={2}
                        primarycolor="#f3f3f3"
                        secondarycolor="#ecebeb"
                        style={{ width: '100%' }}
                        {...props}
                    >
                        <rect x="0%" y="1%" rx="0" ry="0" height="98%" style={{ width: '24.5%' }}/>
                        <rect x="25%" y="1%" rx="0" ry="0" height="98%" style={{ width: '74.5%' }}/>

                    </ContentLoader>
                </Col>
            </Row>
        )
    }

};

export default projectListItemPlaceholder;