import axios from 'axios';

let projects = null;
let ctas = null;

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

if (process.env.REACT_APP_BUILD_FOR_ARCHIVAL === 'true') {
    // Function to fetch the projects.json file
    const fetchProjectsJson = async () => {
        if (!projects) {
            try {
                const response = await axios.get('/api/v1/projects.json');
                projects = response.data;
            } catch (error) {
                console.error('Error fetching projects.json:', error);
            }
        }
    };

    const fetchCtas = async () => {
        if (!ctas) {
            try {
                const response = await axios.get('/api/v1/cta.json');
                ctas = response.data;
            } catch (error) {
                console.error('Error fetching projects.json:', error);
            }
        }
    }

    // Add a request interceptor
    instance.interceptors.request.use(async (config) => {
        let url = config.url;

        // these load composites of multiple subroutes
        if (url.includes('/projects'))
            await fetchProjectsJson(); // Ensure projects.json is fetched
        if (url.includes('/cta'))
            await fetchCtas();

        // This is necessary to ensure that the archived frontend does not accidentally call to a different origin.
        // In this way, it will always to its current origin.
        config.baseURL = window.location.origin + '/api/v1';

        // In the case of campaigns without categories, the api is still called with the query, causing the lookup to fail
        // even though the appropriate response is in there. This code fixes that URL accordingly, leading to the
        // next if-condition to match
        if ((url === '/projects/?category=') && !projects[url]) {
            url = '/projects/?category=undefined';
        }
        if (url === '/projects/?category=undefined' && !projects[url]) {
            url = '/projects';
        }

        // Check if the URL matches any entry in the projects.json file
        if (projects && projects[url]) {
            // If a match is found, return the data from the projects.json file
            return {
                ...config,
                adapter: () => {
                    return new Promise((resolve) => {
                        resolve({
                            data: projects[url],
                            status: 200,
                            statusText: 'OK',
                            headers: {},
                            config,
                            request: {},
                        });
                    });
                },
            };
        }
        // Check if the cta-composite was loaded (which only happens when running in archival-mode)
        if (ctas && url.includes('/cta')) {
            // since the composite contains all ctas for all pages, we need to make sure only the ones for the page
            // requested will be returned to the code.
            const searchParams = new URLSearchParams(url);
            const page = searchParams.get('page') ?? 'home'; // this is the server default, so '/cta/' will return 'home' page ctas
            const ctasForPage = ctas.filter(cta => cta.page === page);

            if (page) {
                return {
                    ...config,
                    adapter: () => {
                        return new Promise((resolve) => {
                            resolve({
                                data: ctasForPage,
                                status: 200,
                                statusText: 'OK',
                                headers: {},
                                config,
                                request: {},
                            });
                        });
                    },
                };
            }
        }

        // Since there is no backend anymore, sending the analytics-state is unsupported
        // it can simply be answered as successful.
        if (['post', 'POST'].includes(config.method) && url.includes('/analytics')) {
            return {
                ...config,
                adapter: () => {
                    return new Promise((resolve) => {
                        resolve({
                            status: 201,
                            statusText: 'OK',
                            headers: {},
                            config,
                            request: {},
                        });
                    });
                },
            };
        }

        // If BUILD_FOR_ARCHIVAL is true, append .json to non-project URLs
        if (!url.startsWith('/projects/')) {
            // Remove trailing slash if present
            if (url.endsWith('/')) {
                config.url = url.slice(0, -1);
            }
            config.url = `${config.url}.json`;
        }
        // If no match is found, proceed with the actual request
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
}

export default instance;
