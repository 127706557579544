import React from 'react';
import classes from './Gallery.module.scss';

import { Row } from 'reactstrap';

const gallery = (props) => {
    return (
        <Row className={props.isSignup ? '' : (classes.Slider + " mx-3 mb-3 mb-lg-0")}>
            {props.children}
        </Row>
    );
};

export default gallery;
