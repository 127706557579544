import React, { useState, useEffect } from 'react';
import Aux from '../../hoc/Aux/Aux';
import ProjectListItemPlaceholder from '../../components/UI/Placeholder/ProjectListItemPlaceholder/ProjectListItemPlacholder';
import CallToActionPlaceholder from "../../components/UI/Placeholder/CallToActionPlaceholder/CallToActionPlaceholder";
import ProjectListItem from './ProjectListItem/ProjectListItem';
import ProjectListCounter from './ProjectListCounter/ProjectListCounter';
import CallToAction from '../../components/CallToAction/CallToAction';
import styles from './ProjectList.module.scss';
import axios from '../../axios-base';
import { Link, useLocation, useHistory} from 'react-router-dom';
import {Row, Col, Input, Label} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {default as CustomInput}  from '../../components/UI/Input/FormInput';
import {sendVirtualPageView} from '../../shared/utility';
import qs from 'qs';
import ReactJoyride, {STATUS} from 'react-joyride';
import Cookies from "js-cookie";
import {Helmet} from "react-helmet";


function ProjectListHook(props) {

    const [projectsCount, setProjectsCount] = useState(0);
    const [projects, setProjects] = useState(null);
    const [phrase, setPhrase] = useState('');
    const [categoryOptions, setCategoryOptions] = useState(null);
    const [categorySelected, setCategorySelected] = useState('');
    const [cta, setCta] = useState(null);
    const [steps, setSteps] = useState([]);
    const [inputSelection, setInputSelection] = useState(true);

    let history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const cta = axios.get('/cta/',{params: {page: 'projekte'}})
        const options = axios.get('/options/')

        Promise.all([cta, options]).then(([resCta, resOptions]) => {
            setCta(resCta.data[0]);

            let availableCategories = resOptions.data[0].projectForm.projectCategory;
            if(availableCategories && availableCategories.elementConfig.options.length > 1) {
                let firstOption = () => {
                    if(availableCategories.dependent){
                        return {value: "", displayValue: "Alle"}
                    } else {
                        return {value: "", displayValue: "Wählen Sie eine " + availableCategories.elementConfig.label, disabled: true}
                    }
                }
                availableCategories.elementConfig.options.unshift(firstOption());
                availableCategories.elementConfig.label = "Wählen Sie eine " + availableCategories.elementConfig.label;
                setCategoryOptions(availableCategories);
                setSteps([{
                    target: '.category-select',
                    content: `Über das Dropdown-Menü können Sie Ihre Auswahl jederzeit anpassen. ${availableCategories.elementConfig.label } wie z.B. ${availableCategories.elementConfig.options[1].displayValue} oder ${availableCategories.elementConfig.options[2].displayValue}`,
                    disableBeacon: true,
                    placement: 'top'
                }]);
            } else {
                setSteps([{
                    target: '.project-search',
                    content: 'Suchen Sie nach Ihren gewünschten Projekten',
                    disableBeacon: true,
                    placement: 'top'
                }]);
            }
        }).catch((error) => {
           console.log(error);
        });
        sendVirtualPageView("Teilnehmer");
    }, []);

    useEffect(() => {
        const query = qs.parse(location.search.slice(1)).category;
        setProjects(null);
        axios.get(`/projects/?category=${query}`)
            .then(response => {
                setProjects(response.data);
                setProjectsCount(response.data.length);
                setCategorySelected(query !== undefined ?  query : "");
                setInputSelection(true);
            })
            .catch(error => {
                console.log(error);
            });
    }, [location])


    const categoryHandler = (e) => {
        const selectedCategory = e.target.value;
        setPhrase('');
        setInputSelection(false);
        if(selectedCategory !== categorySelected){
            setCategorySelected(selectedCategory);
            history.push({
                search: `?category=${selectedCategory}`
            });
        }
    };

    const onChangeHandler = (e) => {
        setPhrase(e.target.value);
        if (categorySelected !== '' && categoryOptions.dependent) {
            setCategorySelected('');
            history.push({
                search: `?category=`
            });
        }
    };

        let categoryForm;
        if (categoryOptions){
            categoryForm = (
                <CustomInput
                    wrapperCSSClass='category-select mb-3'
                    elementType={categoryOptions.elementType}
                    elementConfig={categoryOptions.elementConfig}
                    value={categorySelected}
                    changed={(e) => categoryHandler(e)}
                    disabled={!inputSelection}
                    shouldValidate={categoryOptions.valid} />
            );
        }

        let projectsRender = (
            <Aux>
                <ProjectListItemPlaceholder />
                <ProjectListItemPlaceholder />
                <ProjectListItemPlaceholder />
            </Aux>
        );

        if (props.votingPhases.type && projects) {
            projectsRender = projects
                .filter((project) => {
                    // @todo: Filter is associated with phases, therefore it can move up component hierarchy to app.js or be encompassed in the following reduce method
                    if(props.votingPhases.type.length > 1){
                        //we have a 2 part campaign
                        if (props.votingPhases.voteStartedFinale) {
                            return project.isFinalist;
                        } else {
                            return project;
                        }
                    } else {
                        //global
                        return project;
                    }

                })
                .reduce((displayedProjects, project) => {
                    let projectVoteCount = '-';
                    let projectRank = '-';
                    if(props.votingPhases.type.length > 1){
                        // @todo: We can move the project rank check out of the phase check as it is repeated for both phases
                        if (props.votingPhases.voteStartedFinale) {
                            if(project.voteCountFinale) {
                                projectVoteCount = project.voteCountFinale;
                                projectRank = project.rank;
                            }
                        } else {
                            if(props.votingPhases.voteStarted && project.voteCount) {
                                projectVoteCount = project.voteCount;
                                projectRank = project.rank;
                            }
                        }
                    } else {
                        // @Todo: We can remove voteStartedFinale since this is now redundant? Potential refactor needed
                        if(props.votingPhases.voteStartedFinale && project.voteCountFinale) {
                            projectVoteCount = project.voteCountFinale;
                            projectRank = project.rank;
                        }
                    }

                    let projectLink = (
                        // Todo: Deep-Link zur Ressource definieren und Pretty-Link erstellen
                        <Link className={`${styles.ProjectListLink} anl_project-list--project-lnk`} to={'./' + project._id} key={project._id}>
                            <ProjectListItem
                                display={project.contestantName.toLowerCase().includes(phrase.toLowerCase()) ? "" : "d-none"}
                                rank={projectRank}
                                title={project.contestantName}
                                subtitle={project.projectTitle}
                                voteCount={projectVoteCount}
                            />
                        </Link>
                    );
                    displayedProjects.push(projectLink);
                    return displayedProjects;
                }, []);
        }

        let ctaRender = <CallToActionPlaceholder/>;

        if (cta){
            ctaRender = <CallToAction
                btnClass={`anl_cta--${cta.page}--btn-${cta.position}`}
                headline={cta.headline}
                copy={cta.copy}
                buttonText={cta.buttonText}
                buttonColor={cta.buttonColor}
                linkUrl={cta.linkUrl}
                outline={cta.outline}
                buttonVisibility={cta.buttonVisibility}
            />
        }

        return (
            <Aux>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PROJECT_TITLE}: Teilnehmer`}</title>
                </Helmet>
                {Cookies.get('bigfoot_cookie-consent') && !Cookies.get('bigfoot_tour-projects') && <ReactJoyride
                    steps={steps}
                    disabledScrolling
                    hideCloseButton
                    scrollDuration={800}
                    styles={{
                        options: {
                            zIndex: 1021,
                            primaryColor: styles.primaryColor,
                        },
                        tooltipContent: {
                            padding: '0px'
                        }
                    }}
                    locale={{back: 'Zurück', close: 'Schließen', last: 'Letzte', next: 'Nächste', open: 'Öffnen', skip: 'Überspringen'}}
                    callback={({ status }) => {
                        if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
                            Cookies.set('bigfoot_tour-projects', true, {expires: 180});
                        }
                    }}
                    floaterProps = {{
                        disableAnimation: true,
                        disableFlip: true}} /> }

                {/* CallToAction */}
                <Row className="my-3">
                    <Col>
                        {ctaRender}
                    </Col>
                </Row>
                <Row className="my-2">
                    <ProjectListCounter
                        projectsCount={projectsCount}
                        categories={categoryOptions}/>
                </Row>
                <Row >
                    <Col className="mb-3 project-search">
                        <Label for="Teilnehmer suchen">Suchen Sie nach einem Teilnehmernamen</Label>
                        <div className={styles.SearchWrapper + " position-relative"}>
                            <FontAwesomeIcon className={styles.SearchIcon} icon={faSearch}/>
                            <Input disabled={!inputSelection} onChange={onChangeHandler} className={styles.SearchField + " me-sm-2"} placeholder="Teilnehmer suchen" type="search" aria-label="Teilnehmer suchen" value={phrase}/>
                        </div>
                    </Col>
                    {categoryForm}
                </Row>
                <Row>
                    <Col className="px-0 p-lg-3 min-vh-100">
                        {projectsRender}
                    </Col>
                </Row>
            </Aux>
        );

}

export default ProjectListHook;
