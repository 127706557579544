import React from "react";
import "./CarouselAssets.module.scss";
import icon from "../../../../assets/images/sms-feature-ablaufgrafik-desktop-step3-rgb.svg";
import {ReactSVG} from "react-svg";

function Icon({afterInjection}) {
    return (
        afterInjection ? <ReactSVG src={icon} afterInjection={afterInjection}/> : <ReactSVG src={icon}/>
    );
}

export default Icon;
