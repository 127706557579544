import React from "react";

/**
 * #96e3fa lightest
 * #5fcced lighter
 * #00a2d4 base
 * #009dd4 base
 */

import "./CarouselAssets.module.scss";
import icon from "../../../../assets/images/sms-feature-ablaufgrafik-mobil-step2-rgb.svg";
import {ReactSVG} from "react-svg";

function Icon({afterInjection}) {
  return (
      afterInjection ? <ReactSVG src={icon} afterInjection={afterInjection}/> : <ReactSVG src={icon}/>
  );
}

export default Icon;
