import PageNotFoundLg from '../../assets/images/Seitenichtgefunden-lg-rgb.svg'
import PageNotFoundXs from '../../assets/images/Seitenichtgefunden-xs-rgb.svg'
import React from "react";
import {ReactSVG} from "react-svg";

export default function PageNotFoundHeader() {
    return (
        <div>
            <ReactSVG src={window.innerWidth >= 768 ?  PageNotFoundLg : PageNotFoundXs}/>
        </div>
    );
}