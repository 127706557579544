import React from 'react';
import classes from './GalleryItem.module.scss';

import { Card, CardImg, Col } from 'reactstrap';

const galleryItem = (props) => {
    return (
        <Col xs="11" sm="6" md="6" lg="6" className="p-1">
            <Card inverse className={classes.Slide}>
                <CardImg src={props.src} alt={props.alt} />
            </Card>
        </Col>
    );
};

export default galleryItem;